$blue: #003da6;
$white: #ffff;
$purple: #9D2085;
$lightPurple: #f7e8f3;

.colorWhite {
  color: $white !important;
}

.colorBlue {
  color: $blue !important;
}

.colorRed {
  color: red !important;
}


.colorPurple {
  color: $purple !important;
}

.bgWhite {
  background-color: $white !important;
}

.bgBlue {
  background-color: $blue !important;
}

.bgPurple {
  background-color: $purple !important;
}

.borderBlue {
  border: 1px solid $blue !important;
}

.textAlignLeft {
  text-align: start;
}

.textAlignCenter {
  text-align: center;
}

.textAlignRight {
  text-align: end;
}

.textAlignJustify {
  text-align: justify;
}

.width100 {
  width: 100%;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexLeft {
  display: flex;
  justify-content: left;
  align-items: center;
}

.flexRight {
  display: flex;
  justify-content: right;
  align-items: center;
}


.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1 {
  font-weight: bold !important;
  color: $blue;
  text-align: center;
  text-transform: uppercase;
}



.navStepper {
  .customButt {
    margin-right: 1vw;
  }
}

.customButt {
  img {
    height: 30px;
    width: auto;
    padding-right: 10px;
  }

  .flexButt {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .saveAndGo {
    height: 30px;
    font-size: 10px;
  }
}


.transButt {
  background-color: $white !important;
  border-color: $white !important;

  .logoutImg {
    transform: rotate(180deg);
  }
}


.purpleButt {
  background-color: $purple !important;
  border-color: $purple !important;
}

.blueButt {
  background-color: $blue !important;
  border-color: $blue !important;
}

.whitePurpleButt {
  background-color: $white !important;
  border-color: $purple !important;
  color: $purple !important;
}


.App {
  min-height: 100vh;
  background-color: $white;
}

.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}


header {
  height: 45px;
  width: 100vw;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $purple;

  button {
    background-color: $blue !important;
    margin-right: 1vw;
  }
}

.headerTextLogoutHelp {
  a {
    color: $blue;
  }

  height: 3vh !important;
  padding-left: 2.5vh !important;
  padding-right: 2.5vh !important;
}

@media screen and (max-width: 768px) {
  .logoH {
    display: none;
  }

  .iconH {
    display: block;
  }


  .hoverButtMail,
  .hoverButtLogOut {
    display: none !important;
  }

}

@media screen and (min-width: 769px) {
  .logoH {
    display: block;
  }

  .iconH {
    display: none;
  }

  .headerTextLogoutHelp {
    display: none !important;
  }


}


@media screen and (max-width: 1199px) {

  header {
    // padding-top: 5vh !important;
    padding-left: 2vh !important;
    padding-right: 2vh !important;
  }

  .saveAndGo {
    font-size: 9px !important;
  }

}

@media screen and (min-width: 1200px) {

  header {
    // padding-top: 5vh !important;
    padding-left: 10vh !important;
    padding-right: 10vh !important;
  }

}


// .miniStepper {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 5px;
//   border-radius: 5px;
//   width: 500px;
// }

.containerPages {
  min-height: 94vh;
  // width: 100vw;
  background-color: $white;
  overflow-y: auto;
  // padding: 0px!important;
}


// @media screen and (max-width: 768px) {
//   .containerPages {
//     padding-top: 5vh !important;
//     padding-left: 2vh !important;
//     padding-right: 2vh !important;
//   }
// }

// @media screen and (min-width: 769px) and (max-width: 1199px) {
//   .containerPages {
//     padding-top: 5vh !important;
//     padding-left: 10vh !important;
//     padding-right: 10vh !important;

//   }

// }

// @media screen and (min-width: 1200px) {

//   .containerPages {
//     padding-top: 5vh !important;
//     padding-left: 30vh !important;
//     padding-right: 30vh !important;
//   }

// }


.navStepper {
  --bs-gutter-x: 0 !important;
  // background-color: $lightPurple;
  // border-bottom: $purple 1px solid;

  .purpleButt {
    margin-right: 1vw;
  }

  .titleStep {
    padding-left: 1vw;
  }
}

.miniStepper {
  // background-color: $blue; 
  align-items: center;
  padding-left: 1vw !important;
  padding-right: 1vw !important;
  padding-top: 1vh !important;
  padding-bottom: 1vh !important;

  .ballsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    // background-color: $blue;
    padding: 5px;
    border-radius: 5px;

    div {
      // background-color: $blue;
      border-radius: 50%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-left: 1vw !important;
      margin-right: 1vw !important;
      font-weight: 700;
      // color: $white!important;
    }
  }
}

@media screen and (max-width: 768px) {
  .ballsContainer {
    div {
      height: 20px !important;
      width: 20px !important;
      font-size: 8px !important;
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1199px) {
  .ballsContainer {
    div {
      height: 30px !important;
      width: 30px !important;
    }
  }
}

@media screen and (min-width: 1200px) {
  .ballsContainer {
    div {
      height: 30px !important;
      width: 30px !important;
    }
  }
}

// .modal {
//   color: $blue;
// }

.login {
  .loginCard {
    min-width: 200px;
    max-width: 400px;
    max-height: 800px;
    border-radius: 25px;
    padding: 25px;
  }

  input {
    line-height: 2;
    border: none;
    border-bottom: 1px solid $purple;
    text-align: center;
  }

  .btn {
    border-radius: 10px !important;
    background-color: $blue;
  }


  .changeCard {
    display: flex;

    a {
      color: $blue !important;
    }

    a:hover {
      text-decoration: underline !important;
      cursor: pointer !important;
    }
  }



}

.logged {


  @media screen and (max-width: 768px) {
    .desktopLoggedVersion {
      display: none;
    }
  }

  @media screen and (min-width: 769px) {
    .mobileLoggedVersion {
      display: none;
    }

  }


  h2,
  h3,
  h4 {
    font-weight: bold;
    color: $blue;
  }

  .icon_frame {
    width: 100%;
    text-align: center;
    margin-bottom: 2vh;
    margin-top: 2vh;

    img {
      width: 30%;

    }
  }

  // .nav {
  //   justify-content: center;
  // }

  // @media screen and (max-width: 1199px) {
  //   .containerLogger {
  //     padding-top: 5vh!important;
  //     padding-left: 10vh!important;
  //     padding-right: 10vh!important;
  //   }

  // }

  // @media screen and (min-width: 1200px) {
  //   .containerLogger {
  //     padding-top: 5vh!important;
  //     padding-left: 30vh!important;
  //     padding-right: 30vh!important;
  //   }

  // }

}

.basicForm {
  .checkGroup {
    div {
      display: inline-block;
    }

    label {
      margin-left: 2vh;
    }

    a {
      color: $blue;
    }



    // .form-check-input:focus {
    //   border-color: $lightPurple;
    //   background: $white;
    // }
    // .form-check-input {
    //   background: $purple;
    //   color: $white;
    // }
  }

  .radioSchoolContainer {
    label {
      margin-left: 0 !important;
      margin-right: 2vh;
    }
  }

  #privacyRequired {
    visibility: hidden;
    font-size: small;
  }


  //   @media screen and (max-width: 1199px) {
  //     .containerForm {
  //       padding-top: 5vh!important;
  //       padding-left: 10vh!important;
  //       padding-right: 10vh!important;
  //     }

  //   }

  //   @media screen and (min-width: 1200px) {
  //     .containerForm {
  //       padding-top: 5vh!important;
  //       padding-left: 30vh!important;
  //       padding-right: 30vh!important;
  //     }

  //   }
}

.testMotiva {
  .nav {
    justify-content: space-between;
  }

  #spinnerContainer {
    margin-top: 15vh;

    .text-primary {
      color: $blue !important;
    }
  }

  #iframeAndForm {
    visibility: hidden;
  }

  #doYouHaveSomeCoach {
    display: none;
  }

}


#emptyInputPdf,
#wrongInputPdf {
  visibility: hidden;
  font-size: small;
}


.calendly {
  .nav {
    justify-content: center;
  }

  .buttContainer {
    padding: 30px;
    // margin: 30px;
    // border: $blue 1px solid;
  }

  min-height: 90vh;
}

@media screen and (max-width: 1199px) {

  .logged,
  .basicForm,
  .testMotiva,
  .testMotivaSurvey,
  .calendly,
  .resultSurvey,
  .videoCallSurvey,
  .videoCall {
    // padding-top: 5vh !important;
    padding-left: 2vh !important;
    padding-right: 2vh !important;
  }

}

@media screen and (min-width: 1200px) {

  .logged,
  .basicForm,
  .testMotiva,
  .testMotivaSurvey,
  .calendly,
  .resultSurvey,
  .videoCallSurvey,
  .videoCall {
    // padding-top: 5vh !important;
    padding-left: 10vh !important;
    padding-right: 10vh !important;
  }

}


.videoCall {

  // .nav {
  //   justify-content: space-between;
  // }
  .framePlaceholder {
    img {
      margin-top: 5vh;
      border-radius: 5px;
    }
  }

  min-height: 94vh;
}



.contentContainer {
  // padding-left: 1vw!important;
  // padding-right: 1vw!important;
  // padding-top: 1vh!important;
  // padding-left: 1vh!important;

  h1 {
    padding-top: 1vh;
    padding-bottom: 1vh;
    text-transform: uppercase;
  }

  button {
    background-color: $blue;
  }
}

.advisoryModel {
  // margin-bottom: 10vh;
  min-height: 90vh;

  #textLoader {
    width: 100%;
    text-align: center;
    color: #003da6;
    letter-spacing: 1vw;
  }

  @media screen and (max-width: 768px) {
    .advisoryModelContent {
      padding-top: 5vh !important;
      padding-left: 2vh !important;
      padding-right: 2vh !important;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1199px) {
    .advisoryModelContent {
      padding-top: 5vh !important;
      padding-left: 10vh !important;
      padding-right: 10vh !important;

    }

  }

  @media screen and (min-width: 1200px) {
    .advisoryModelContent {
      padding-top: 5vh !important;
      padding-left: 30vh !important;
      padding-right: 30vh !important;
    }

  }

  h1 {
    padding-top: 1vh;
    padding-bottom: 1vh;
  }

  button {
    background-color: $blue;
  }

  #lottieFrame {
    margin-top: 10vh;
  }

  .jobCard {
    text-align: center;

    img {
      width: 50%;
      height: auto;
      border-radius: 50%;
      margin-top: 1vh;
      margin-bottom: 2vh;
    }
  }

}

.result {
  .row {
    padding: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  @media screen and (max-width: 1199px) {
    .resultContent {
      padding-top: 5vh !important;
      padding-left: 2vh !important;
      padding-right: 2vh !important;
    }

  }

  @media screen and (min-width: 1200px) {
    .resultContent {
      padding-top: 5vh !important;
      padding-left: 10vh !important;
      padding-right: 10vh !important;
    }

  }



  .resultContent {
    .card-body {
      height: 100% !important;
    }

    .r_parent {
      height: 100% !important;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .labelCourseType {
      padding-bottom: 0px !important;
      display: flex;
      justify-content: left;
      align-items: flex-end;
      border-bottom: 1px solid $purple;
    }

    .containerTitleValue {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $lightPurple;

      .titleElemePath {
        margin-top: 1vh;
        text-transform: uppercase;
        font-weight: bold;
        font-size: x-small;
      }

      .valueElemePath {
        text-transform: capitalize;
      }

    }



    .jobImage {
      width: 100;
      text-align: center;

      img {
        border-radius: 25px;
      }

    }
  }

  // h1 {
  //   padding-top: 1vh;
  //   padding-bottom: 1vh;
  // }

  button {
    background-color: $blue;
  }

  // .roadImgFrame {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // border-radius: 50%;

  // }

  .card {
    border: none;

    img {
      width: 30px;
      height: 30px;
      // margin-top: 1vh;
      // margin-bottom: 2vh;
    }

    .centerFlex {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding-bottom: 2vh;
    }


    .cardRowTextContent {
      border-left: 1px solid $purple;
      // padding-bottom: 2vh;
    }

    .ral {
      color: $purple;
      font-weight: 900;
      // border-left: 1px solid $purple;
      margin-bottom: 5vh;

    }
  }

  .chartPerSe {
    // background-color: $blue;
    padding: 5vh;

  }

  @media screen and (max-width: 1199px) {
    .chartContainer {
      padding-top: 5vh !important;
      padding-left: 10vh !important;
      padding-right: 10vh !important;
    }

  }

  @media screen and (min-width: 1200px) {
    .chartContainer {
      padding-top: 5vh !important;
      padding-left: 30vh !important;
      padding-right: 30vh !important;
    }

  }

}


#myDIV div {
  display: none;
}

.iframeStyle {
  min-height: 90vh;
  width: 100%;
  height: 100%;
}


#pdfHide {
  max-height: 297mm;
  max-width: 210mm;
  margin: 10mm;
  // padding: 20mm;
  // background-color: red;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 100%;
  // height: 100%;

  .r_parent {
    border: none;

    .r_child {
      border: none;
    }

    .titleElemePath {
      margin-right: 10px;
    }

  }
}


.footerContent {
  border-top: 1px solid $blue;
  min-height: 3vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
}



@media screen and (max-width: 1199px) {
  .CookieConsent {
    justify-content: end !important;
  }

  .footerContent {
    padding-left: 10vh !important;
    padding-right: 10vh !important;

  }

}

@media screen and (min-width: 1200px) {
  .footerContent {
    padding-left: 30vh !important;
    padding-right: 30vh !important;
  }

}



.coach {
  form {
    height: 500px;
    width: 100%;
  }


}